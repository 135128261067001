<template>
  <div>
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Añadir un nuevo schedule
      </v-card-title>
      <div v-if='masive'>
        <v-container>
          <v-row>
              <v-col cols="12" md="6">
                <v-file-input
                    show-size
                    counter
                    @change="selectFile"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    label="File input"
                ></v-file-input>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                    :items="[',',';']"
                    label="Delimitador"
                    v-model="delimiter"
                ></v-select>
              </v-col>
          </v-row>
        </v-container>

      </div>
      <div v-else>
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <v-date-picker first-day-of-week="1" v-model="schedule.day"></v-date-picker>
            </v-col>
            <v-col cols="12" md="6">
              <v-time-picker
                  v-model="schedule.time"
                  format="24hr"
              ></v-time-picker>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <v-alert
          text
          prominent
          type="error"
          icon="mdi-cloud-alert"
          v-show="errorMessage"
      >
        No ha sido posible eliminar el horario.  {{errorMessage}}
      </v-alert>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            v-show="!sending"
            color="red"
            text
            @click="cancel"

        >
          Cancelar
        </v-btn>
        <v-btn
            :loading="sending"
            color="primary"
            text
            @click="create"
        >
          Crear
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import {AmphiSchedules} from "@/services/admin.service";

export default {
  name: "NewSchedule",
  props:{
    masive: {
      type: Boolean,
      default: false
    },
    event_id:{
      type:Number,
      required:false
    }
  },
  data(){
    return{
      sending : false,
      schedule:{
        day: null,
        time: null,
      },
      file:null,
      error: false,
      delimiter:null,
      errorMessage: null,
    }
  },
  methods:{
    cancel(){
      this.sending = false
      this.schedule={
        day: null,
        time: null,
      },
          this.file = null
      this.error = false
      this.$emit('cancel')
    },
    selectFile(file){
      console.log(file)
      this.file = file
    },
    create(){
      if(this.masive){

        AmphiSchedules().masiveCreate(this.file, this.delimiter).then(
            this.cancel
        ).catch(
            e => this.errorMessage = e
        )
      }else{
        const schedule = new Date(this.schedule.day + " " + this.schedule.time)

        AmphiSchedules()
            .create(
                {
                  schedule : schedule.toISOString(),
                  amphi_event_id: this.event_id
                }
            ).then(
            this.cancel
        ).catch(
            e => this.errorMessage = e
        )
      }
    }
  }
}
</script>

<style scoped>

</style>