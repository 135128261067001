<template>
  <div>
    <v-card
        :loading="sending">
      <v-card-title class="text-h5 grey lighten-2">
        Añadir un nuevo evento
      </v-card-title>
      <v-form v-model="valid">
        <v-container>
          <v-row>
            <v-col
                cols="12"
                md="12"
            >
              <v-text-field
                  v-model="amphiEvent.name"
                  label="Nombre del evento"
                  required
                  :disabled="editing"

              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                md="12"
            >
              <v-textarea
                  outlined
                  label="Descripcion del evento"
                  v-model="amphiEvent.description"
              ></v-textarea>

            </v-col>

            <v-col
                cols="12"
                md="6"
            >
              <v-text-field
                  append-icon="mdi-currency-eur"
                  v-model="amphiEvent.price"
                  label="Precio"
                  required
              ></v-text-field>
            </v-col>

            <v-col
                cols="12"
                md="6"
            >
              <v-select
                  v-model="amphiEvent.category_id"
                  :items="categories"
                  item-text="name"
                  item-value="id"
                  label="Categoria"
                  :disabled="editing"
              ></v-select>
            </v-col>
            <div v-if="!editing">
              <v-col cols="12" md="6">
                <v-file-input
                    :rules="rules"
                    accept="image/png, image/jpeg, image/bmp"
                    placeholder="Selecciones Una portada"
                    prepend-icon="mdi-camera"
                    label="Portada"
                    @change="uploadImage"
                ></v-file-input>
              </v-col>
              <v-col cols="12" md="6" class="d-sm-none d-md-block">

                <v-img :src="preview_link"></v-img>

              </v-col>
            </div>
          </v-row>
        </v-container>
      </v-form>
      <v-alert
          text
          prominent
          type="error"
          icon="mdi-cloud-alert"
          v-show="error"
      >
        No ha sido posible eliminar el evento.  {{errorMessage}}
      </v-alert>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            v-show="!sending"
            color="red"
            text
            @click="cancel"

        >
          Cancelar
        </v-btn>
        <v-btn
            :loading="sending"
            color="primary"
            text
            @click="()=>editing? editEvent() :createEvent()"
        >
          {{editing? 'Editar': 'Crear'}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import {Category} from "@/services/api";
import {AmphiEvents, AmphiPoster} from "@/services/admin.service";

export default {
  name: "NewEvent",
  props: {
    'event_id':{
      type: Number
    }
  },
  data(){
    return {
      sending: false,
      valid : false,
      categories: [],
      rules: [
        value => !value || value.size < 2000000 || 'Poster size should be less than 2 MB!',
      ],
      amphiEvent:{
        name: '',
        description:'',
        price: 0,
        amphitheater_id: 1,
        poster_id: 1,
        category_id: null
      },
      error: false,
      errorMessage: '',
      preview_link:null,
      editing: false,
    }
  },
  mounted() {
    this.sending = true
    Category().get().then(
        res => {
          this.categories = res.data.data
          if(this.event_id){
            this.editing = true;
            this.getEvent(this.event_id)
          }
        }
    ).finally(
        () =>{
          if(!this.event_id) {
            this.sending = false;
          }
        }
    )

  },
  methods:{
    createEvent(){
      this.sending = true;
      this.amphiEvent.price = this.amphiEvent.price * 100
      AmphiEvents().create(this.amphiEvent)
          .then(
              this.cancel
          )
          .catch(
              e =>{
                this.error = true;
                this.errorMessage = e;
              }
          )
          .finally(
              () =>{
                this.sending = false;
              }

          )
    },
    cancel() {
      this.amphiEvent = {
        name: '',
        description:'',
        price: 0,
        amphitheater_id: 1,
        poster_id: 1,
        category_id: null
      }
      this.preview_link  = null
      this.error= false
      this.errorMessage= ''
      this.sending= false
      this.editing= false
      this.$emit('cancel')
    },
    uploadImage(file){
      AmphiPoster().create(file).then(
          res => {
            const poster_data = res.data.data
            console.log(poster_data.filepath)
            this.preview_link = `${this.$store.state.api_backend}storage/${poster_data.filepath}`
            this.amphiEvent.poster_id = poster_data.id
          }
      )
    },
    getEvent(id){
      console.log(id)
      AmphiEvents().retrieve(id).then(
          res => {
            const eventData = res.data.data
            const eventCategory = this.categories.find( category => category.name === eventData.category)
            this.amphiEvent = eventData
            this.amphiEvent.category_id = eventCategory.id
            this.amphiEvent.price = this.amphiEvent.price / 100
            this.sending = false
          }
      )
    },
    editEvent(){
      this.sending = true;
      this.amphiEvent.price = this.amphiEvent.price * 100
      AmphiEvents().update(this.event_id, this.amphiEvent)
          .then(
              this.cancel
          )
          .catch(
              e =>{
                this.error = true;
                this.errorMessage = e;
              }
          )
          .finally(
              () =>{
                this.sending = false;
              }

          )
    }
  }
}
</script>

<style scoped>

</style>