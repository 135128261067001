<template>
  <div>
    <v-container>
      <h3>Proximo Evento</h3>
      <v-row>
        <v-col md="4">
          <stat-card
              v-if="next_event.name"
              title="Nombre:"
              icon="mdi-drama-masks"
              :sub-title="next_event.name"
              color="#2069a2"
              font-color="white"
          ></stat-card>
          <v-sheet
              v-else
              color="grey lighten-4"
              class="pa-3"
          >
            <v-skeleton-loader
                class="mx-auto"
                max-height="80"
                type="card"
            ></v-skeleton-loader>
          </v-sheet>
        </v-col>
        <v-col md="4">
          <stat-card
              v-if="next_event.time"
              title="En:"
              icon="mdi-calendar"
              :sub-title="$moment(next_event.time).locale('es').fromNow()"
              color="orange"
              font-color="white"
          ></stat-card>
          <v-sheet
              v-else
              color="grey lighten-4"
              class="pa-3"
          >
            <v-skeleton-loader
                class="mx-auto"
                max-height="80"
                type="card"
            ></v-skeleton-loader>
          </v-sheet>
        </v-col>
        <v-col md="4">
          <stat-card
              v-if="next_event.capacity !== null"
              title="Ocupacion"
              icon="mdi-seat"
              :sub-title="`${next_event.capacity.toFixed(2)} %`"
              color="green"
              font-color="white"
          ></stat-card>
          <v-sheet
              v-else
              color="grey lighten-4"
              class="pa-3"
          >
            <v-skeleton-loader
                class="mx-auto"
                max-height="80"
                type="card"
            ></v-skeleton-loader>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-data-table
          :headers="headers"
          :items="amphievents"
          :loading="loading"
          group-by="category"
          class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar
              flat
          >
            <v-toolbar-title>Eventos</v-toolbar-title>
            <v-divider
                class="mx-4"
                inset
                vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <!--            Nuevo evento-->
            <v-dialog
                v-model="dialog"
                max-width="700px"
            >
              <!--              Open dialog-->
              <NewEvent @cancel="closeDialog"></NewEvent>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    dark
                    class="ma-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="() => dialog=true"
                >
                  Nuevo Evento
                </v-btn>
              </template>
            </v-dialog>
            <!--            carga masiva-->
            <v-dialog
                v-model="masive"
                max-width="700px"
            >

              <new-schedule @cancel="masive=false" :event_id="$route.params.id" masive></new-schedule>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    dark
                    class="ma-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="()=> masive=true"
                >
                  Carga Masiva
                </v-btn>
              </template>
            </v-dialog>
            <router-link to="/empleados/anfiteatro">
            <v-btn
                color="primary"
                dark
                class="ma-2"
                v-bind="attrs"
                v-on="on"
            >
              Anfiteatro
            </v-btn>
            </router-link>
          </v-toolbar>
          <v-alert
              text
              prominent
              type="error"
              icon="mdi-cloud-alert"
              v-show="error"
          >
            No ha sido posible eliminar el evento.  {{errorMessage}}
          </v-alert>
        </template>
        <template v-slot:item.price="{item}">
          {{item.price | money}}
        </template>
        <template v-slot:item.description="{item}">
          {{item.description | str_limit(90)}}
        </template>
        <template v-slot:item.actions="{item}">
          <v-icon
              small
              class="mr-2"
              @click="() => editItem(item.id)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              small
              class="mr-2"
              @click="() => $router.push({ name: 'AdminEvent', params: { id: item.id } })"

          >
            mdi-eye

          </v-icon>
          <v-icon
              small
              @click="()=>deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>

      </v-data-table>
      <v-dialog
          v-model="edit_event"
          max-width="700px"
      >
        <!--              Open dialog-->
        <NewEvent :event_id="edit_event_id" @cancel="closeEditEvent"></NewEvent>

      </v-dialog>

    </v-container>
  </div>
</template>

<script>
import {AmphiEvents} from "@/services/admin.service";
import NewEvent from "@/views/admin/NewEvent";
import StatCard from "@/components/StatCard";
import NewSchedule from "@/views/admin/NewSchedule";

export default {
  name: "Dashboard",
  components: {NewSchedule, StatCard, NewEvent},
  data () {
    return {
      edit_event: false,
      edit_event_id: 0,
      dialog: false,
      loading: true,
      error: false,
      next_event: {
        name: null,
        time: null,
        capacity: null
      },
      reservations: 0,
      errorMessage:'',
      headers: [
        { text: 'ID', value: 'id'},
        { text: 'Nombre', value: 'name' },
        { text: 'Descripcion', value: 'description', sortable:false },
        { text: 'Categoria', value: 'category' },
        { text: 'Precio', value: 'price'},
        { text: 'Acciones', value: 'actions', sortable:false  },
      ],
      masive: false
    }
  },
  metaInfo() {
    return {
      title: "Dashboard | La Marina Anfiteatro",
      meta: [
        { name: 'description', content:  'Administrador de La Marina Resort Anfiteatro'},
      ]
    }
  },
  mounted() {
    this.$store.commit('setLoading', false)
    this.getItems()

  },
  methods:{
    getItems(){
      AmphiEvents().list().then(
          result => {
            this.$store.commit('setEvent', result.data.data)
            this.loading = false
            this.getNextEvent()
          }
      )
    },
    editItem(id){
      this.edit_event_id = id
      this.edit_event = true
    },
    closeEditEvent(){
      this.edit_event_id = 0
      this.edit_event = false
      this.getItems()
    },
    closeDialog(){
      this.dialog = false
      this.getItems()
    },
    deleteItem(item){
      this.loading= true
      AmphiEvents().delete(item.id)
          .then(this.getItems)
          .catch(
              e => {
                this.error = true
                this.errorMessage = e
              }
          ).finally(
          () => {
            this.loading = false
          }
      )
    },
    getTickets(){

    },
    getNextEvent(){
      const reservations = AmphiEvents().get_reservations()
      const schedule = AmphiEvents().next_event()
      Promise.all([reservations, schedule])
          .then(
              res => {
                const next_schedule = res[1].data.data
                const reservations = res[0].data.data.filter(
                    elem => elem.event_schedule_id.id == next_schedule.id && elem.receipt
                ).length
                this.next_event.time = next_schedule.schedule
                this.next_event.capacity = (reservations/162) * 100
                this.next_event.name = this.amphievents.find(
                    elem => elem.id == next_schedule.amphi_event_id
                ).name
              }
          )
    }

  },
  computed: {
    amphievents(){
      return this.$store.state.amphiEvents
    }
  }
}
</script>

<style scoped>

</style>