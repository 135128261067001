var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('h3',[_vm._v("Proximo Evento")]),_c('v-row',[_c('v-col',{attrs:{"md":"4"}},[(_vm.next_event.name)?_c('stat-card',{attrs:{"title":"Nombre:","icon":"mdi-drama-masks","sub-title":_vm.next_event.name,"color":"#2069a2","font-color":"white"}}):_c('v-sheet',{staticClass:"pa-3",attrs:{"color":"grey lighten-4"}},[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"max-height":"80","type":"card"}})],1)],1),_c('v-col',{attrs:{"md":"4"}},[(_vm.next_event.time)?_c('stat-card',{attrs:{"title":"En:","icon":"mdi-calendar","sub-title":_vm.$moment(_vm.next_event.time).locale('es').fromNow(),"color":"orange","font-color":"white"}}):_c('v-sheet',{staticClass:"pa-3",attrs:{"color":"grey lighten-4"}},[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"max-height":"80","type":"card"}})],1)],1),_c('v-col',{attrs:{"md":"4"}},[(_vm.next_event.capacity !== null)?_c('stat-card',{attrs:{"title":"Ocupacion","icon":"mdi-seat","sub-title":((_vm.next_event.capacity.toFixed(2)) + " %"),"color":"green","font-color":"white"}}):_c('v-sheet',{staticClass:"pa-3",attrs:{"color":"grey lighten-4"}},[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"max-height":"80","type":"card"}})],1)],1)],1)],1),_c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.amphievents,"loading":_vm.loading,"group-by":"category"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Eventos")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"700px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"color":"primary","dark":""},on:{"click":function () { return _vm.dialog=true; }}},'v-btn',attrs,false),on),[_vm._v(" Nuevo Evento ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('NewEvent',{on:{"cancel":_vm.closeDialog}})],1),_c('v-dialog',{attrs:{"max-width":"700px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"color":"primary","dark":""},on:{"click":function (){ return _vm.masive=true; }}},'v-btn',attrs,false),on),[_vm._v(" Carga Masiva ")])]}}]),model:{value:(_vm.masive),callback:function ($$v) {_vm.masive=$$v},expression:"masive"}},[_c('new-schedule',{attrs:{"event_id":_vm.$route.params.id,"masive":""},on:{"cancel":function($event){_vm.masive=false}}})],1),_c('router-link',{attrs:{"to":"/empleados/anfiteatro"}},[_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"color":"primary","dark":""}},'v-btn',_vm.attrs,false),_vm.on),[_vm._v(" Anfiteatro ")])],1)],1),_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.error),expression:"error"}],attrs:{"text":"","prominent":"","type":"error","icon":"mdi-cloud-alert"}},[_vm._v(" No ha sido posible eliminar el evento. "+_vm._s(_vm.errorMessage)+" ")])]},proxy:true},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("money")(item.price))+" ")]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("str_limit")(item.description,90))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function () { return _vm.editItem(item.id); }}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function () { return _vm.$router.push({ name: 'AdminEvent', params: { id: item.id } }); }}},[_vm._v(" mdi-eye ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function (){ return _vm.deleteItem(item); }}},[_vm._v(" mdi-delete ")])]}}])}),_c('v-dialog',{attrs:{"max-width":"700px"},model:{value:(_vm.edit_event),callback:function ($$v) {_vm.edit_event=$$v},expression:"edit_event"}},[_c('NewEvent',{attrs:{"event_id":_vm.edit_event_id},on:{"cancel":_vm.closeEditEvent}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }