<template>
  <v-card :color="color" :class="`${fontColor}--text`">
    <v-container>
      <v-row align="center">
        <v-col>
          <slot>
            <h5 class="text-uppercase mb-0" v-if="title">{{title}}</h5>
            <h2 class="font-weight-bold mb-0" v-if="subTitle">{{subTitle}}</h2>
          </slot>
        </v-col>
        <v-col>
          <slot>
            <v-icon class="float-end mr-4" :color="fontColor">{{icon}}</v-icon>
          </slot>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "StatCard",
  props:['title', 'subTitle', 'icon','color','fontColor']
}
</script>

<style scoped>

</style>